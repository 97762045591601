import { createSlice } from "@reduxjs/toolkit";

export const onboardedSlice = createSlice({
  name: "onboarded",
  initialState: false,
  reducers: {
    setOnboarded: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOnboarded } = onboardedSlice.actions;

export default onboardedSlice.reducer;
