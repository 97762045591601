import { createSlice } from "@reduxjs/toolkit";
import {
  BANNER_GET_STARTED,
  BANNER_DEAL,
  BANNER_GOOGLE_SITEMAP,
  BANNER_HOMEPAGE_HINT,
  BANNER_SEO_GUIDE,
  BANNER_WHATS_NEW,
  BANNER_BETTERDOCS,
  BANNER_SCHEDULE_A_CALL,
  BANNER_PARTNERSHIPS,
} from "storeseo-enums/cacheKeys";

const initialState = {
  [BANNER_GET_STARTED]: true,
  [BANNER_DEAL]: true,
  [BANNER_GOOGLE_SITEMAP]: true,
  [BANNER_SEO_GUIDE]: true,
  [BANNER_HOMEPAGE_HINT]: true,
  [BANNER_WHATS_NEW]: true,
  [BANNER_BETTERDOCS]: true,
  [BANNER_SCHEDULE_A_CALL]: true,
  [BANNER_PARTNERSHIPS]: true,
};

export const HiddenBannerSlice = createSlice({
  name: "hiddenBanner",
  initialState: { ...initialState },
  reducers: {
    hideGetStarted: (state, action) => {
      state.getStarted = false;
    },

    hideSeoGuide: (state, action) => {
      state.seoGuide = false;
    },

    hideDeal: (state, action) => {
      state.deal = false;
    },

    hideHomepageHint: (state, action) => {
      state.homepageHint = false;
    },

    hideSitemapsGoogleConnect: (state, action) => {
      state.sitemapsGoogleConnect = false;
    },

    setHiddenBanner: (state, action) => {
      const { bannerKey, status } = action.payload;
      return (state = { ...state, [bannerKey]: status });
    },

    setAllBanner: (state, action) => {
      return (state = { ...state, ...action.payload });
    },

    resetAllBanner: (state, action) => {
      return (state = { ...initialState });
    },
  },
});

export const {
  setHiddenBanner,
  setAllBanner,
  hideSeoGuide,
  hideDeal,
  hideHomepageHint,
  hideGetStarted,
  hideSitemapsGoogleConnect,
} = HiddenBannerSlice.actions;

export default HiddenBannerSlice.reducer;
