import { createSlice } from "@reduxjs/toolkit";

export const collectionCountSlice = createSlice({
  name: "collectionCount",
  initialState: 0,
  reducers: {
    setCollectionCount: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCollectionCount } = collectionCountSlice.actions;

export default collectionCountSlice.reducer;
