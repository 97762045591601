import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
  total: null,
  synced: null,
};

export const docSyncSlice = createSlice({
  name: "docSync",
  initialState: { ...initialState },
  reducers: {
    setDocSyncStatus: (state, action) => {
      state.ongoing = action.payload;
    },

    setDocSyncInfo: (state, action) => {
      const { total, synced } = action.payload;
      state.total = total;
      state.synced = synced;
    },

    resetDocSyncInfo: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setDocSyncStatus, setDocSyncInfo, resetDocSyncInfo } = docSyncSlice.actions;
export default docSyncSlice.reducer;
