import { createSlice } from "@reduxjs/toolkit";

export const onboardStepSlice = createSlice({
  name: "onboardStep",
  initialState: null,
  reducers: {
    setOnboardStep: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOnboardStep } = onboardStepSlice.actions;

export default onboardStepSlice.reducer;
