import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
};

export const optimizationTaskSlice = createSlice({
  name: "optimizationTask",
  initialState: { ...initialState },
  reducers: {
    setOptimizationTaskOngoing: (state, action) => {
      state.ongoing = action.payload;
    },
  },
});

export const { setOptimizationTaskOngoing } = optimizationTaskSlice.actions;
export default optimizationTaskSlice.reducer;
