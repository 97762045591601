import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
  total: null,
};

export const blogSyncSlice = createSlice({
  name: "blogSync",
  initialState: { ...initialState },
  reducers: {
    setBlogSyncStatus: (state, action) => {
      state.ongoing = action.payload;
    },

    setBlogSyncInfo: (state, action) => {
      const { total } = action.payload;
      state.total = total;
    },

    resetBlogSyncInfo: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setBlogSyncInfo, setBlogSyncStatus, resetBlogSyncInfo } = blogSyncSlice.actions;
export default blogSyncSlice.reducer;
