import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
  total: null,
};

export const pageSyncSlice = createSlice({
  name: "pageSync",
  initialState: { ...initialState },
  reducers: {
    setPageSyncStatus: (state, action) => {
      state.ongoing = action.payload;
    },

    setPageSyncInfo: (state, action) => {
      const { total } = action.payload;
      state.total = total;
    },

    resetPageSyncInfo: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setPageSyncInfo, setPageSyncStatus, resetPageSyncInfo } = pageSyncSlice.actions;
export default pageSyncSlice.reducer;
