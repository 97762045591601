import { createSlice } from "@reduxjs/toolkit";

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: null,
  reducers: {
    setCampaign: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
