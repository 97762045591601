import { createSlice } from "@reduxjs/toolkit";

export const docCountSlice = createSlice({
  name: "docCount",
  initialState: 0,
  reducers: {
    setDocCount: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDocCount } = docCountSlice.actions;

export default docCountSlice.reducer;
