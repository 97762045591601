import { createSlice } from "@reduxjs/toolkit";

export const productCountSlice = createSlice({
  name: "productCount",
  initialState: 0,
  reducers: {
    setProductCount: (state, action) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProductCount } = productCountSlice.actions;

export default productCountSlice.reducer;
