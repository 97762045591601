import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
  total: null,
  synced: null,
};

export const productSyncSlice = createSlice({
  name: "productSync",
  initialState: { ...initialState },
  reducers: {
    setProductSyncStatus: (state, action) => {
      state.ongoing = action.payload;
    },

    setProductSyncInfo: (state, action) => {
      const { total, synced } = action.payload;
      state.total = total;
      state.synced = synced;
    },

    resetProductSyncInfo: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setProductSyncInfo, setProductSyncStatus, resetProductSyncInfo } = productSyncSlice.actions;
export default productSyncSlice.reducer;
