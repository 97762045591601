import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    setUser: (state, action) => {
      return (state = action.payload);
    },
    removeUser: (state) => {
      return (state = {});
    },
    updateUser: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    updateAddons: (state, action) => {
      return (state = { ...state, addons: [...action.payload] });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, updateUser, removeUser, updateAddons } = userSlice.actions;

export default userSlice.reducer;
