import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ongoing: false,
  total: null,
  synced: null,
};

export const collectionSyncSlice = createSlice({
  name: "collectionSync",
  initialState: { ...initialState },
  reducers: {
    setCollectionSyncStatus: (state, action) => {
      state.ongoing = action.payload;
    },

    setCollectionSyncInfo: (state, action) => {
      const { total, synced } = action.payload;
      state.total = total;
      state.synced = synced;
    },

    resetCollectionSyncInfo: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setCollectionSyncInfo, setCollectionSyncStatus, resetCollectionSyncInfo } = collectionSyncSlice.actions;
export default collectionSyncSlice.reducer;
